/*global Component,axios */
class LoginPage extends Component {

    static name() {
        return "loginPage";
    }

    static componentName() {
        return "loginPage";
    }

    getProps() {
        return ['register'];
    }

    getComputed(){
        return {
            disableLoginAction: function () {
                return this.$store.getters.getSetting.DisableLoginAction;
            },
            disableRegisterAction: function () {
                return this.$store.getters.getSetting.DisableRegisterAction;
            }
        };
    }

    data() {
        return {
            username: '',
            password: '',
            userinfo: null,
            readOnly: false,
            inSystem: false,
            registerError: false,
            existsUser: false,
            alertError: [],
            showRegister: false,
            person: {
                Code: null,
                WebUserFlag: true,
                ContactType: null,
                Sex:null,
                Name: "",
                LastName: "",
                LastName2: "",
                Phone: "",
                Email: "",
                JobPosition: null,
                WebUser: "",
                WebPassword: "",
                WebPassword2:null,
                WebAccessGroup: "",
                CountryCode: null,
                Country: "",
                TaxNr: "",
                Customer: "",
            }
        };
    }

    getWatch() {
        return {
            "$store.getters.getIsLogin": 'closeModal',
        };
    }

    mounted() {
        return async function  () {
            this.mustRedirect();
        };
    }

    getMethods() {
        return {
            mustRedirect:this.mustRedirect,
            clear:this.clear,
            login:this.login,
            checkUser:this.checkUser,
            closeModal: this.closeModal
        };
    }

    mustRedirect() {
        if (this.$route.query.redirect)
            return self.$store.commit('updateRedirectLogin', window.atob(this.$route.query.redirect));
        if (this.$store.getters.getIsLogin) {
            return self.$router.go(this.$store.state.afterLoginPath);
        }
    }

    clear() {
        this.username = '';
        this.password = '';
    }

    async login() {
        let loginResult = await this.$store.dispatch('login', {
            username: this.username,
            password: this.password
        });
        if (!loginResult.ok) {
            this.$store.dispatch('showNotificacion', {
                title: this.tr('Login'),
                message: this.tr("Incorrect Username or Password"),
                type: 'error'
            });
            this.clear();
        }

        if (this.$store.state.mustUpdateInfo === true)
            this.router.push('/mustUpdateInfo');
        if (this.$store.getters.getIsLogin) {
            this.clear();
            if (!this.$store.state.redirectAfterLogin) {
                //this.$store.commit( 'updateItemsIsLoaded', false );
                this.$router.push(this.$store.state.afterLoginPath);
            } else {
                let redirectPath = this.$store.state.redirectAfterLogin;
                this.$store.dispatch('setRedirectLogin', null);
                this.$store.dispatch('activePreserveCar', false);
                this.$router.push(redirectPath);
            }
            //this.$router.push('indicators')
        } else {
            this.$store.dispatch('showNotificacion', {
                title: this.tr('Login'),
                message: this.tr("Incorrect Username or Password"),
                type: 'error'
            });
        }
    }

    async checkUser() {
        if (this.$store.getters.getSetting.OnlyEmailUsers) {
            const mailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if(!mailReg.test(this.username)) {
                this.alertError = ['FORMATVALUEERR', "Email"];
                return;
            }
        }
        let check = await axios.get('/ecommerce/api/checkuser/' + this.username);
        this.existsUser = check.data;
        if(!this.existsUser) {
            let prospectPerson = await axios.post('/ecommerce/api/newProspectPerson/' + this.username);
            if(prospectPerson) this.person.Code = prospectPerson.Code; 
            this.person.WebUser = this.username;
            this.person.Email = this.username;
            this.showRegister = true;
        }
    }

    closeModal() {
        if (this.$store.getters.getIsLogin) {
            this.clear();
        } else {
            this.$store.dispatch('showNotificacion', {
                title: this.tr('Login'),
                message: this.tr("Incorrect Username or Password"),
                type: 'error'
            });
        }
    }

    getTemplate() {
        return `<div>
                  <headerSectionComponent></headerSectionComponent>
                  <div :class="classContainer">
                      <div class="section-main">
                        <section class="padding-small">
                          <template v-if="$store.getters.getAppState=='ready'">
                            <div class="container">
                                <div class="row justify-content-center">
                                  <div class="col-lg-8">
                                    <div class="block">
                                      <div class="block-header">
                                        <h5 class="text-center">{{tr('Login')}}</h5>
                                      </div>
                                      <div class="block-body">
                                        <hr>
                                        <form aria-autocomplete="none" autocomplete="off">
                                            <div class="col-sm-12" v-if="alertError.length>0">
                                                <div ref='errorRegisterFrom' class="alert alert-danger alert-dismissible fade show" role="alert" >
                                                    <strong>{{tr(alertError)}}!</strong>
                                                    <button type="button" class="close" data-dismiss="alert" aria-label="Close" @click="alertError=[]">
                                                    <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="col s6 offset-s3 center-align">
                                                <div v-if="!existsUser">
                                                    <div v-if="!showRegister">
                                                        <div class="form-group">
                                                            <input :placeholder="tr('User')" v-model="username" name="username" type="text" class="form-control validate" aria-autocomplete="none" autocomplete="none">
                                                        </div>                                                    
                                                        <button class="center-align btn btn-secondary" @click.prevent="checkUser">{{tr('Continue')}}</button>
                                                    </div>
                                                    <div v-else>
                                                        <PersonGenericComponent :confirmPassword="true" :personObj="person" />
                                                        <div class="form-group" >
                                                            <button class="btn btn-custom btn-custom-login mr-3  float-left" @click="clearForm()">{{tr('LoginText')}}</button>
                                                            <button class="btn btn-secondary btn-custom-register float-right"  @click="emitEvent('person-save')">{{tr('Sign Up')}}</button>
                                                        </div>
                                                    </div>  
                                                </div>
                                            
                                                <div v-else>
                                                    <div class="form-group">
                                                        <input :placeholder="tr('User')" v-model="username" name="username" type="text" class="form-control validate" aria-autocomplete="none" autocomplete="none">
                                                    </div>
                                                    <div class="form-group">
                                                        <input :placeholder="tr('Password')" v-model="password"  id="password" name="password" type="password" class="form-control validate" aria-autocomplete="none" autocomplete="new-password" >
                                                    </div>
                                                    <p class="">
                                                        <input name="redirect_on_success" class="form-control" type="hidden" value="/ecommerce/#/">
                                                    </p>
                                                    <div class="form-group text-right">
                                                        <router-link  to="/home" class="btn btn-light" role="button">{{tr('Close')}}</router-link>
                                                        <button class="center-align btn btn-secondary" @click.prevent="login">{{tr('Login')}}</button>
                                                    </div>
                                                </div>
                                                <hr>
                                                <div class="text-center">
                                                  <span>{{tr('Not yet our customer?')}} </span>
                                                  <router-link  to="/signup" class="btn btn-link" role="button">{{tr('Register')}}</router-link>
                                                  <router-link  to="/recovery_password" class="btn btn-link" role="button">{{tr('Forgot your password?')}}</router-link>
                                                </div>
                                            </div>
                                        </form>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                            </div>
                          </template>
                          <template v-if="$store.getters.getAppState=='onload'">
                              <div class="loading">
                                  <i class="fas fa-circle-notch fa-spin fa-3x"></i>
                              </div>
                          </template>
                        </section>
                      </div>
                  </div>
                  <footerSectionComponent></footerSectionComponent>
              </div>`;
    }
}

LoginPage.registerComponent();